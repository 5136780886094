$section-padding: 3rem;

.section {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    &.sab {
        .icon-block img {
            width: 100%;
            padding-top: 25px;
            max-width: 200px;
        }

        .flow-text {
            font-size: 1.2rem;
            line-height: 125%;
        }
    }

    .material-icons {
        color: var(--md-sys-color-primary) !important;
    }
}