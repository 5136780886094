// Utility Color Classes
// Code fon materializecss übernommen und für CNTX Farben angepasst

//.success {
//
//}

@use "sass:map";

$cntx-red: (
  "lighten-5":  #fbe8e8,
  "lighten-4":  #f6c6c6,
  "lighten-3":  #f0a0a1,
  "lighten-2":  #ea7a7b,
  "lighten-1":  #e65e5e,
  "base":       #e14142,
  "darken-1":   #dd3b3c,
  "darken-2":   #d93233,
  "darken-3":   #d52a2b,
  "darken-4":   #cd1c1d,
  "accent-1":   #ffffff,
  "accent-2":   #ffcfd0,
  "accent-3":   #ff9c9d,
  "accent-4":   #ff8384
);


$mycolors: (
  "cntx-red": $cntx-red,
);

$colors: map.merge($colors, $mycolors) !global;

// Color Classes

@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text {
        color: $color_value !important;
      }
    }
    @else if $color_name != "shades" {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}

.cntx {
  --md-sys-color-primary: #e14142;
  --md-sys-color-surface-tint: rgb(144 74 70);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(170 95 91);
  --md-sys-color-on-primary-container: rgb(255 255 255);
  --md-sys-color-secondary: rgb(110 47 45);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(170 95 91);
  --md-sys-color-on-secondary-container: rgb(255 255 255);
  --md-sys-color-tertiary: rgb(110 47 45);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(170 95 91);
  --md-sys-color-on-tertiary-container: rgb(255 255 255);
  --md-sys-color-error: rgb(110 47 45);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(170 95 91);
  --md-sys-color-on-error-container: rgb(255 255 255);
  --md-sys-color-background: rgb(255 248 247);
  --md-sys-color-on-background: rgb(35 25 25);
  --md-sys-color-surface: rgb(245 250 251);
  --md-sys-color-on-surface: rgb(23 29 30);
  --md-sys-color-surface-variant: rgb(219 228 230);
  --md-sys-color-on-surface-variant: rgb(59 68 70);
  --md-sys-color-outline: rgb(87 97 98);
  --md-sys-color-outline-variant: rgb(115 124 126);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(43 49 51);
  --md-sys-color-inverse-on-surface: rgb(236 242 243);
  --md-sys-color-inverse-primary: rgb(255 179 174);
  --md-sys-color-primary-fixed: rgb(170 95 91);
  --md-sys-color-on-primary-fixed: rgb(255 255 255);
  --md-sys-color-primary-fixed-dim: rgb(141 72 68);
  --md-sys-color-on-primary-fixed-variant: rgb(255 255 255);
  --md-sys-color-secondary-fixed: rgb(170 95 91);
  --md-sys-color-on-secondary-fixed: rgb(255 255 255);
  --md-sys-color-secondary-fixed-dim: rgb(141 72 68);
  --md-sys-color-on-secondary-fixed-variant: rgb(255 255 255);
  --md-sys-color-tertiary-fixed: rgb(170 95 91);
  --md-sys-color-on-tertiary-fixed: rgb(255 255 255);
  --md-sys-color-tertiary-fixed-dim: rgb(141 72 68);
  --md-sys-color-on-tertiary-fixed-variant: rgb(255 255 255);
  --md-sys-color-surface-dim: rgb(213 219 220);
  --md-sys-color-surface-bright: rgb(245 250 251);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(239 245 246);
  --md-sys-color-surface-container: rgb(233 239 240);
  --md-sys-color-surface-container-high: rgb(227 233 234);
  --md-sys-color-surface-container-highest: rgb(222 227 229);
}


