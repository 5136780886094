.sidenav {
    li {
        a {
            color: var(--md-sys-color-primary) !important;
        }
    }
}

.button-collapse {
    color: #26a69a;
}