.contact {
    $size: 175px;
    $padding: 5px;
  
    .card-panel {
      padding: $padding;
      height: $size + 2 * $padding;
      width: $size + 2 * $padding;
      margin: 0 auto;
      border-radius: 50%;
  
      img {
        width: $size;
        height: $size;
        border-radius: 50%;
      }
    }
  
    .details {
      width: $size;
      padding: 0;
      padding-left: 1rem;
      margin: 2rem auto;
  
      p {
        // height: 36px;
        font-weight: 300;
        text-align: left;
  
        &.name {
          font-weight: 500;
          margin-top: 0;
        }
  
        .material-icons {
          color: color("cntx-red", "base");
          font-size: 1.5rem;
          //margin-right: -13px;
          line-height: inherit;
          margin-top: 1px;
        }
  
        span {
          //padding-top: 12px;
          font-size: 1.1rem;
          display: table-cell;
        }
      }
    }
  }
  