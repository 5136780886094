nav {
    right: auto;

    background-color: rgba(255, 255, 255, 0.95);
    color: var(--md-sys-color-primary);
    border-top: 15px solid color("cntx-red", "base");
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.16),
        0 5px 10px -5px rgba(0, 0, 0, 0.12); // nur unten
    height: 79px;
    transition: all 0.4s ease;
    max-width: 1900px;

    img.logo,
    svg.logo {
        height: 42px;
        width: auto;
        vertical-align: top;
        transition: all 0.4s ease;
        padding-top: 0;
        margin-top: 10px;

        path.fil0 {
            fill: color("grey", "darken-3");
        }
    }

    a {
        line-height: calc(var(--navbar-height) - 10px);
        color: color("grey", "darken-3");

        &.brand-logo.right {
            transition: all 0.4s ease;
            padding-top: 0;
        }
    }

    ul {
        &.left {
            padding-top: 0;
            transition: all 0.4s ease;
        }

        a {
            font-size: 1rem;
            font-weight: 300;
            transition: all 0.4s ease;
            color: color("grey", "darken-3");
        }

        li {
            padding: 0 10px;

            &.small a {
                font-size: 80%;
            }

            &.small .material-icons {
                transform: scale(0.5);
                color: color("grey", "lighten-1");
            }

            a {
                padding: 0 5px;
                display: inline;

                &:hover,
                &.active {
                    background: inherit !important;
                    border-bottom: 3px solid color("cntx-red", "base");
                }

                color: var(--md-sys-color-primary) !important;
            }
        }
    }
}

@media only screen and (min-width: 993px) {
    nav.grow {
        height: 109px;

        img.logo {
            height: 72px;
            padding-top: 0;
            vertical-align: middle;
        }

        ul {
            a {
                font-size: 1.5rem;
            }

            &.left {
                padding-top: 20px;
            }
        }
    }
}