html {
    font-family: "Source Sans 3 VF", sans-serif;
    font-weight: 300;
}

body {
    max-width: 1900px;
    margin: 0 auto;
    background-color: color("grey", "lighten-5");
}

strong {
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 300;
    color: var(--md-sys-color-primary);
}

h1 {
    font-style: italic;
    font-family: "Source Serif";

    @media #{$small-and-down} {
        font-size: 3rem;
    }
}

h5 {
    @media #{$small-and-down} {
        font-size: 1.25rem;
    }
}

p {
    line-height: 2rem;
}

.flow-text {
    line-height: 150%;
}

.icon-block {
    padding: 0 15px;

    .material-icons {
        font-size: inherit;
    }
}

ul {
    list-style-type: '| ';
    list-style-position: outside;
    padding-left: 1em;

    li {
        &::marker {
            font-weight: bold;
            color: #999 !important;
        }

        margin-top: 1ex;
    }
}

img.blur {
    filter: blur(5px);
    opacity: 0.25;
}


.no-mar-bot {
    margin-bottom: 0;
}

.info_content {
    h5 {
        margin-bottom: 0;
    }

    a {
        color: inherit !important;
    }

    .row {
        margin-bottom: 0;

        p {
            white-space: nowrap;
            font-size: 85%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}