footer.page-footer {
    margin-top: 0;
    font-size: 90%;
    background-color: color("grey", "lighten-4");
    color: var(--md-sys-color-on-primary);
    border: none;

    .disclaimer {
        color: color("grey", "darken-3");

        h5,
        p {
            font-size: 95%;
            color: inherit;
        }

        h5 {
            font-weight: 400;
            margin-top: 21px;
        }
    }

    div.remarks {
        color: color("grey", "darken-2");
        background-color: color("grey", "lighten-3");

        div.esf {
            transition: all 1s ease;
            overflow: hidden;
            max-height: 500px;

            &.zero {
                max-height: 0;
            }

            h5 {
                color: inherit;
            }
        }
    }

    img {
        vertical-align: middle;
        margin-top: 20px;

        &.esf {
            height: 46px;
            margin-bottom: -20px;

            &:hover {
                cursor: pointer;
            }

            &:not(:first-of-type) {
                margin-left: 50px;
            }
        }

        &.oes {
            height: 75px;
            margin-left: 40px;
            margin-bottom: 10px;
        }
    }

    .footer-copyright {
        background-color: #c31525;
        height: 48px;
        line-height: 48px;

        img.esf {
            background-color: white;
            border: 5px solid white;
            border-radius: 2px;
        }
    }

    p {
        line-height: 135%;
    }
}

/* small and down */
@media only screen and (max-width: 600px) {
    .footer-copyright {
        height: auto !important;
    }
}

/* very small */
@media only screen and (max-width: 384px) {
    footer {
        img {
            &.oes {
                margin-top: 40px;
            }
        }
    }
}