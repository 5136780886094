  $carousel-height-normal: 480px;
  $carousel-height-smallscreen: 100vh;
  $carousel-smallscreen-space: 200px;

  .carousel {
      --carousel-height: #{$carousel-height-normal};

      @media #{$small-and-down} {
          --carousel-heighta: calc((#{$carousel-height-smallscreen} - #{$carousel-smallscreen-space}) / 2);
      }

      &.carousel-slider {
          .carousel-item {
              img {
                  max-width: 100%;
                  max-height: calc(var(--carousel-height) - 50px);
                  padding: 25px 0 50px 0;
                  box-sizing: border-box;

                  &:not(.img-no-drop-shadow) {
                      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                  }
              }
              .col:has(img) {
                  text-align: center;
              }
          }

          .indicators .indicator-item {

              &,
              &.active {
                  background-color: #000;
              }

              opacity: 0.25;

              &.active {
                  opacity: 0.5;
              }
          }

      }
  }