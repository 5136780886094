.parallax-container {
    min-height: 600px;
    line-height: 0;
    height: auto;

    .section {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        position: absolute;
        top: 10%;

        h1 span,
        h5 span {
            background: rgba(255, 255, 255, 0.75);
            padding: 2px 5px;
            line-height: 150%;
        }

        #s404 & h1 {
            font-size: 8.4rem;
        }

        #home &.bg1 {
            min-height: 300px;
        }

        @media #{$medium-and-down} {
            position: absolute;
        }
    }

    .parallax {

        img {
            transform: translate3d(-50%, 200px, 0px);
            display: block;
            filter: drop-shadow(5px 5px 5px #00000080);

            @media #{$medium-and-down} {
                min-height: initial;
                max-width: 1400px;
            }
        }
    }
}