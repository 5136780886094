@import "npm:source-sans/source-sans-3VF.css";
@import "npm:source-serif/source-serif-subhead.css";

@import "@materializecss/materialize/sass/materialize";
@import "color";
@import "base";
@import "nav";
@import "sidenav";
@import "sections";
@import "contact";
@import "parallax";
@import "carousel";
@import "footer";

/* Besonderheiten nach Bildschirmauflösung */
/* large an up */
@media only screen and (min-width: 993px) {
    .bg1 {
        margin-top: 15px;
    }
}


/* med and up */
@media only screen and (min-width: 601px) {
    .hide-on-med-and-up {
        display: none !important;
    }
}

/* med and down */
@media only screen and (max-width: 992px) {
    #index-banner .section {
        top: 10%;
    }
}

/* small and down */
@media only screen and (max-width: 600px) {
    #index-banner .section {
        top: 0;
    }
}